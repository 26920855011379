import NavTitle from '@basicComponents/nav-title';
import React, {useEffect, useState} from 'react';
import {View, StyleSheet} from 'react-native';
import theme from '@style';
import LazyImage from '@basicComponents/image';
import {goCS, goTo, toPriceStr} from '@/utils';
import Text from '@basicComponents/text';
import globalStore from '@/services/global.state';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {NavTitleProps} from '@basicComponents/nav-title/nav-title';
import WalletWeb from './wallet-web';
import Wallet from './wallet';
import {Subject, takeUntil} from 'rxjs';
import DownloadProgress from './progress';
import {useTranslation} from 'react-i18next';
// import Price from '../price';

/** 这个组件的leftNode会显示在客服按钮的右侧, rightNode会显示在钱包的左侧 */
const DetailNavTitle = (
  props: NavTitleProps & {
    hideServer?: boolean;
    showProgress?: boolean;
    hideAmount?: boolean;
    rate?: number;
    serverRight?: boolean;
  },
) => {
  const {
    title,
    rate = 0,
    hideServer,
    serverRight = false,
    showProgress = false,
    hideAmount,
    leftNode,
    rightNode,
    ...otherProps
  } = props;
  const {i18n} = useTranslation();
  const [login, setLogin] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const distory = new Subject<boolean>();
  useEffect(() => {
    globalStore.tokenSubject.pipe(takeUntil(distory)).subscribe(token => {
      setLogin(!!token);
    });
    globalStore.amountChanged.pipe(takeUntil(distory)).subscribe(res => {
      setAmount(res.current);
    });
    return () => {
      distory.next(true);
      distory.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderService = (
    <NativeTouchableOpacity onPress={goCS}>
      <LazyImage
        occupancy={'transparent'}
        width={theme.iconSize.l}
        height={theme.iconSize.l}
        imageUrl={require('@components/assets/icons/headphone.webp')}
      />
    </NativeTouchableOpacity>
  );
  return (
    <View style={[theme.fill.fillW, theme.flex.col, styles.zIndexTop]}>
      <NavTitle
        leftNode={
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            {!hideServer && !serverRight && renderService}
            {leftNode && (
              <View
                style={[hideServer || serverRight ? null : theme.margin.leftl]}>
                {leftNode}
              </View>
            )}
          </View>
        }
        rightNode={
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            {rightNode && (
              <View
                style={[
                  (hideAmount || !login) && !serverRight
                    ? null
                    : theme.margin.rightl,
                ]}>
                {rightNode}
              </View>
            )}
            {serverRight && (
              <View style={[hideAmount ? null : theme.margin.rightl]}>
                {renderService}
              </View>
            )}
            {!hideAmount && (
              <View style={[theme.position.rel]}>
                <View
                  style={[
                    theme.flex.row,
                    theme.flex.centerByCol,
                    theme.position.abs,
                    // eslint-disable-next-line react-native/no-inline-styles
                    {
                      right: 28,
                      top: globalStore.isWeb ? 18 : 16,
                    },
                  ]}>
                  <View
                    style={[
                      theme.flex.col,
                      theme.flex.alignEnd,
                      theme.margin.rightxxs,
                    ]}>
                    <Text
                      fontSize={theme.fontSize.xs}
                      style={[theme.font.second]}>
                      Balance
                    </Text>
                    {/* <Price
                      price={login ? amount : undefined}
                      textProps={{second: true}}
                      suffixUnit="K"
                    /> */}
                    <Text fontFamily="fontInter" blod second>
                      {login ? toPriceStr(amount, {suffixUnit: 'K'}) : '-'}
                    </Text>
                  </View>
                </View>
                <NativeTouchableOpacity
                  onPress={() => {
                    globalStore.token ? goTo('Recharge') : goTo('Login');
                  }}>
                  {globalStore.isWeb ? (
                    <WalletWeb
                      style={[
                        {
                          marginTop: -theme.paddingSize.l,
                        },
                      ]}
                    />
                  ) : (
                    <Wallet
                      style={[
                        {
                          marginTop: -theme.paddingSize.l,
                        },
                      ]}
                    />
                  )}
                </NativeTouchableOpacity>
              </View>
            )}
          </View>
        }
        title={title || i18n.t('loading')}
        {...otherProps}
      />
      {showProgress && globalStore.isAndroid && rate > 0 && (
        <DownloadProgress rate={rate} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  zIndexTop: {
    zIndex: 999,
  },
});

export default DetailNavTitle;
